@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon/icomoon.eot?srf3rx");
  src: url("../fonts/icomoon/icomoon.eot?srf3rx#iefix") format("embedded-opentype"), url("../fonts/icomoon/icomoon.ttf?srf3rx") format("truetype"), url("../fonts/icomoon/icomoon.woff?srf3rx") format("woff"), url("../fonts/icomoon/icomoon.svg?srf3rx#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* =======================================================
*
* 	Template Style 
*	Edit this section
*
* ======================================================= */
body {
  font-family: "Source Sans Pro", Arial, sans-serif;
  line-height: 1.8;
  font-size: 16px;
  background: #fff;
  color: #848484;
  font-weight: 300;
  overflow-x: hidden;
}
body.fh5co-offcanvas {
  overflow: hidden;
}

a {
  color: #FCC72C;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
a:hover {
  text-decoration: none !important;
  color: #FCC72C !important;
}

p, ul, ol {
  margin-bottom: 1.5em;
  font-size: 20px;
  color: #848484;
  font-family: "Source Sans Pro", Arial, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  color: #5a5a5a;
  font-family: "Source Sans Pro", Arial, sans-serif;
  font-weight: 400;
  margin: 0 0 30px 0;
}

figure {
  margin-bottom: 2em;
}

::-webkit-selection {
  color: #fcfcfc;
  background: #FCC72C;
}

::-moz-selection {
  color: #fcfcfc;
  background: #FCC72C;
}

::selection {
  color: #fcfcfc;
  background: #FCC72C;
}

#fh5co-header {
  position: relative;
  margin-bottom: 0;
  z-index: 9999999;
}

.fh5co-cover,
.fh5co-hero {
  background: url(../images/bg-hero.gif) center repeat #222222;
  position: relative;
  height: 800px;
}
@media screen and (max-width: 768px) {
  .fh5co-cover,
  .fh5co-hero {
    height: 700px;
  }
}
.fh5co-cover.fh5co-hero-2,
.fh5co-hero.fh5co-hero-2 {
  height: 600px;
}
.fh5co-cover.fh5co-hero-2 .fh5co-overlay,
.fh5co-hero.fh5co-hero-2 .fh5co-overlay {
  position: absolute !important;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  /*background: rgba(66, 64, 64, 0.4)!important;*/
}

.fh5co-overlay {
  position: absolute !important;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: rgba(66, 64, 64, 0.4);
}

.fh5co-cover {
  background-size: cover;
  position: relative;
}
@media screen and (max-width: 768px) {
  .fh5co-cover {
    height: inherit;
    padding: 3em 0;
  }
}
.fh5co-cover .btn {
  padding: 1em 5em;
  opacity: .9;
}
.fh5co-cover.fh5co-cover_2 {
  height: 600px;
}
@media screen and (max-width: 768px) {
  .fh5co-cover.fh5co-cover_2 {
    height: inherit;
    padding: 3em 0;
  }
}
.fh5co-cover .desc {
  top: 50%;
  position: absolute;
  width: 100%;
  margin-top: -80px;
  z-index: 2;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .fh5co-cover .desc {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.fh5co-cover .desc h2 {
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  font-size: 60px;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .fh5co-cover .desc h2 {
    font-size: 40px;
  }
}
.fh5co-cover .desc span {
  display: block;
  margin-bottom: 30px;
  font-size: 28px;
  letter-spacing: 1px;
}
.fh5co-cover .desc span a {
  color: #fff;
}
.fh5co-cover .desc span a:hover, .fh5co-cover .desc span a:focus {
  color: #fff !important;
}
.fh5co-cover .desc span a:hover.btn, .fh5co-cover .desc span a:focus.btn {
  border: 2px solid #fff !important;
  background: transparent !important;
}
.fh5co-cover .desc span a.fh5co-site-name {
  padding-bottom: 2px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
}

/* Superfish Override Menu */
.sf-menu {
  margin: 0 !important;
}

.sf-menu {
  float: right;
}

.sf-menu ul {
  box-shadow: none;
  border: transparent;
  min-width: 12em;
  *width: 12em;
}

.sf-menu a {
  color: rgba(255, 255, 255, 0.5);
  padding: 0.75em 1em !important;
  font-weight: normal;
  border-left: none;
  border-top: none;
  border-top: none;
  text-decoration: none;
  zoom: 1;
  font-size: 18px;
  border-bottom: none !important;
}
.sf-menu a:hover, .sf-menu a:focus {
  color: #fff !important;
}

.sf-menu li.active a:hover, .sf-menu li.active a:focus {
  color: #fff;
}

.sf-menu li,
.sf-menu ul li,
.sf-menu ul ul li,
.sf-menu li:hover,
.sf-menu li.sfHover {
  background: transparent;
}

.sf-menu ul li a,
.sf-menu ul ul li a {
  text-transform: none;
  padding: 0.25em 1em !important;
  letter-spacing: 1px;
}

.sf-menu li:hover a,
.sf-menu li.sfHover a,
.sf-menu ul li:hover a,
.sf-menu ul li.sfHover a,
.sf-menu li.active a {
  color: #fff;
}

.sf-menu ul li:hover,
.sf-menu ul li.sfHover {
  background: transparent;
}

.sf-menu ul li {
  background: transparent;
}

.sf-arrows .sf-with-ul {
  padding-right: 2.5em;
  *padding-right: 1em;
}

.sf-arrows .sf-with-ul:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 1em;
  margin-top: -3px;
  height: 0;
  width: 0;
  border: 5px solid transparent;
  border-top-color: #ccc;
}

.sf-arrows > li > .sf-with-ul:focus:after,
.sf-arrows > li:hover > .sf-with-ul:after,
.sf-arrows > .sfHover > .sf-with-ul:after {
  border-top-color: #ccc;
}

.sf-arrows ul .sf-with-ul:after {
  margin-top: -5px;
  margin-right: -3px;
  border-color: transparent;
  border-left-color: #ccc;
}

.sf-arrows ul li > .sf-with-ul:focus:after,
.sf-arrows ul li:hover > .sf-with-ul:after,
.sf-arrows ul .sfHover > .sf-with-ul:after {
  border-left-color: #ccc;
}

#fh5co-header-section {
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.nav-header {
  position: relative;
  float: left;
  width: 100%;
}

#fh5co-logo {
  position: relative;
  z-index: 2;
  
  font-size: 28px;
  text-transform: uppercase;
  font-weight: 300;
  float: left;
}
#fh5co-logo img {
  height: 42px;
}
@media screen and (max-width: 768px) {
  #fh5co-logo {
    width: 100%;
    float: none;
    text-align: center;
  }
  #fh5co-logo img {
    margin-left: 1em;
  }

}
#fh5co-logo a {
  color: #fff;
}
#fh5co-logo a:hover, #fh5co-logo a:focus {
  color: #fff !important;
}

#fh5co-menu-wrap {
  position: relative;
  z-index: 2;
  display: block;
  float: right;
  margin-top: 22px;
}
#fh5co-menu-wrap .sf-menu a {
  padding: 1em .75em;
}

#fh5co-primary-menu > li > ul li.active > a {
  color: #FCC72C !important;
}
#fh5co-primary-menu > li > .sf-with-ul:after {
  border: none !important;
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  float: right;
  margin-right: -5px;
  top: 50%;
  margin-top: -7px;
  content: "\e922";
  color: rgba(255, 255, 255, 0.5);
}
#fh5co-primary-menu > li > ul li {
  position: relative;
}
#fh5co-primary-menu > li > ul li > .sf-with-ul:after {
  border: none !important;
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  float: right;
  margin-right: 10px;
  top: 20px;
  font-size: 16px;
  content: "\e924";
  color: rgba(0, 0, 0, 0.5);
}

#fh5co-primary-menu .fh5co-sub-menu {
  padding: 10px 7px 10px;
  background: #fff;
  left: 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}
#fh5co-primary-menu .fh5co-sub-menu > li > .fh5co-sub-menu {
  left: 100%;
}

#fh5co-primary-menu .fh5co-sub-menu:before {
  position: absolute;
  top: -9px;
  left: 20px;
  width: 0;
  height: 0;
  content: '';
}

#fh5co-primary-menu .fh5co-sub-menu:after {
  position: absolute;
  top: -8px;
  left: 21px;
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  border-bottom: 8px solid white;
  border-left: 8px solid transparent;
  content: '';
}

#fh5co-primary-menu .fh5co-sub-menu .fh5co-sub-menu:before {
  top: 6px;
  left: 100%;
}

#fh5co-primary-menu .fh5co-sub-menu .fh5co-sub-menu:after {
  top: 7px;
  right: 100%;
  border: none !important;
}

.site-header.has-image #primary-menu .sub-menu {
  border-color: #ebebeb;
  -webkit-box-shadow: 0 5px 15px 4px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 5px 15px 4px rgba(0, 0, 0, 0.09);
  -ms-box-shadow: 0 5px 15px 4px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0 5px 15px 4px rgba(0, 0, 0, 0.09);
  box-shadow: 0 5px 15px 4px rgba(0, 0, 0, 0.09);
}
.site-header.has-image #primary-menu .sub-menu:before {
  display: none;
}

#fh5co-primary-menu .fh5co-sub-menu a {
  letter-spacing: 0;
  padding: 0 15px;
  font-size: 18px;
  line-height: 34px;
  color: #575757 !important;
  text-transform: none;
  background: none;
}
#fh5co-primary-menu .fh5co-sub-menu a:hover {
  color: #FCC72C !important;
}

#fh5co-work-section,
#fh5co-services-section,
#fh5co-content-section,
#fh5co-work-section_2,
#fh5co-blog-section {
  padding: 7em 0;
}
@media screen and (max-width: 768px) {
  #fh5co-work-section,
  #fh5co-services-section,
  #fh5co-content-section,
  #fh5co-work-section_2,
  #fh5co-blog-section {
    padding: 4em 0;
  }
}

.fh5co-section-gray {
  background: url(../images/bg-hero.gif) center repeat #222222; 
  
}

.text-white {
  h1,h2,h3,h4,p{
    color: #ffffff; 
  }
  h1{
    font-size: 50px;
    font-weight: 900!important;
  }
  
}

.heading-section {
  padding-bottom: 1em;
  margin-bottom: 5em;
}
.heading-section:after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 50%;
  width: 100px;
  margin-left: -50px;
  height: 2px;
  background: #FCC72C;
}
.heading-section h3 {
  font-size: 30px;
  letter-spacing: 2px;
  text-transform: uppercase;
  position: relative;
}

.fh5co-grid {
  height: 302px;
  background-size: cover;
  background-position: center center;
  margin-bottom: 30px;
  position: relative;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
}
@media screen and (max-width: 480px) {
  .fh5co-grid {
    height: 400px;
  }
}
.fh5co-grid .image-popup {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
}
.fh5co-grid .image-popup .prod-title {
  position: absolute;
  top: 50%;
  bottom: 20px;
  left: 20px;
  right: 20px;
  margin-top: -25px;
}
.fh5co-grid .image-popup .prod-title h3 {
  margin-bottom: 10px;
  color: #fff;
  font-size: 20px;
}
.fh5co-grid .image-popup .prod-title span {
  color: #fff;
}
.fh5co-grid:hover {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
}
.fh5co-grid:hover .image-popup {
  opacity: 1;
}

.services {
  margin-bottom: 5em;
}
.services span {
  margin-bottom: 30px;
  display: block;
  width: 100%;
}
.services span i {
  font-size: 50px;
  color: #fcc72c;
}
.services h3 {
  font-size: 20px;
  font-weight: 400;
}

#fh5co-services, #fh5co-about, #fh5co-contact {
  padding: 7em 0;
}
@media screen and (max-width: 768px) {
  #fh5co-services, #fh5co-about, #fh5co-contact {
    padding: 4em 0;
  }
}

#fh5co-services .fh5co-number {
  font-size: 30px;
  color: #1fb5f6;
  position: relative;
  padding-top: 10px;
  font-style: italic;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
#fh5co-services .fh5co-number:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 3px;
  top: 0;
  left: 0;
  background: #1fb5f6;
}
#fh5co-services .fh5co-icon {
  margin-bottom: 30px;
  margin-top: 50px;
  position: relative;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
@media screen and (max-width: 768px) {
  #fh5co-services .fh5co-icon {
    margin-top: 0px;
    float: none;
  }
}
#fh5co-services .fh5co-icon i {
  font-size: 200px;
  color: #FCC72C;
}
@media screen and (max-width: 768px) {
  #fh5co-services .fh5co-icon i {
    font-size: 100px;
  }
}

#fh5co-contact .contact-info {
  margin: 0;
  padding: 0;
}
#fh5co-contact .contact-info li {
  list-style: none;
  margin: 0 0 20px 0;
  position: relative;
  padding-left: 40px;
}
#fh5co-contact .contact-info li i {
  position: absolute;
  top: .3em;
  left: 0;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.3);
}
#fh5co-contact .contact-info li a {
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.3);
}
#fh5co-contact .form-control {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 18px;
  font-weight: 400;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
}
#fh5co-contact .form-control:focus, #fh5co-contact .form-control:active {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
#fh5co-contact input[type="text"] {
  height: 50px;
}
#fh5co-contact .form-group {
  margin-bottom: 30px;
}

.fh5co-testimonial {
  position: relative;
  background: #fff;
  padding: 4em 0;
  border-radius: 5px;
  -webkit-box-shadow: 0px 18px 88px -22px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0px 18px 88px -22px rgba(0, 0, 0, 0.58);
  box-shadow: 0px 18px 88px -22px rgba(0, 0, 0, 0.58);
}
@media screen and (max-width: 768px) {
  .fh5co-testimonial {
    border-right: 0;
    margin-bottom: 80px;
  }
}
.fh5co-testimonial h2 {
  font-size: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 50px;
}
.fh5co-testimonial figure {
  position: absolute;
  top: -50px;
  left: 50%;
  margin-left: -44px;
  margin-bottom: 20px;
  background-size: cover;
}
.fh5co-testimonial figure img {
  width: 88px;
  height: 88px;
  border: 10px solid #fff;
  background-size: cover;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.fh5co-testimonial blockquote {
  border: none;
  margin-bottom: 20px;
}
.fh5co-testimonial blockquote p {
  font-style: italic;
  font-size: 26px;
  line-height: 1.6em;
  color: #555555;
}
.fh5co-testimonial span {
  font-style: italic;
}

.fh5co-blog {
  margin-bottom: 60px;
}
.fh5co-blog a img {
  margin-bottom: 30px;
  width: 100%;
  height: 300px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
}
@media screen and (max-width: 768px) {
  .fh5co-blog {
    width: 100%;
  }
}
.fh5co-blog .image-popup {
  opacity: 1;
}
.fh5co-blog .image-popup span {
  display: inline-block;
  margin-bottom: 20px;
}
.fh5co-blog .image-popup span.posted_by {
  font-style: italic;
  color: rgba(0, 0, 0, 0.3);
  font-size: 18px;
}
.fh5co-blog .image-popup span.comment {
  float: right;
}
.fh5co-blog .image-popup span.comment a {
  color: rgba(0, 0, 0, 0.3);
}
.fh5co-blog .image-popup span.comment a i {
  color: #FCC72C;
  padding-left: 7px;
}
.fh5co-blog .image-popup h3 {
  font-size: 26px;
  margin-bottom: 20px;
}
.fh5co-blog .image-popup h3 a {
  color: rgba(0, 0, 0, 0.6);
}

#fh5co-start-project-section {
  padding: 7em 0;
  background: #F64E8B;
}
#fh5co-start-project-section h2 {
  color: white;
  font-size: 60px;
  text-transform: uppercase;
  font-weight: 300;
}
@media screen and (max-width: 768px) {
  #fh5co-start-project-section h2 {
    font-size: 30px;
  }
}
#fh5co-start-project-section a:hover, #fh5co-start-project-section a:focus, #fh5co-start-project-section a:active {
  color: white !important;
}

#map {
  width: 100%;
  height: 500px;
  position: relative;
}
@media screen and (max-width: 768px) {
  #map {
    height: 200px;
  }
}

.fh5co-social-icons > a {
  padding: 4px 10px;
  color: #FCC72C;
}
.fh5co-social-icons > a i {
  font-size: 26px;
}
.fh5co-social-icons > a:hover, .fh5co-social-icons > a:focus, .fh5co-social-icons > a:active {
  text-decoration: none;
  color: #5a5a5a;
}

#footer {
  background: #222831;
  padding: 60px 0 40px;
}
#footer p {
  color: rgba(255, 255, 255, 0.3);
  letter-spacing: 1px;
  font-size: 14px;
  text-transform: uppercase;
}
#footer p a {
  color: rgba(255, 255, 255, 0.5);
}

.btn {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.btn {
  text-transform: uppercase;
  letter-spacing: 2px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.btn.btn-primary {
  background: #FCC72C;
  color: #fff;
  border: none !important;
  border: 2px solid transparent !important;
}
.btn.btn-primary:hover, .btn.btn-primary:active, .btn.btn-primary:focus {
  box-shadow: none;
  background: #FCC72C;
  color: #fff !important;
}
.btn:hover, .btn:active, .btn:focus {
  background: #393e46 !important;
  color: #fff;
  outline: none !important;
}
.btn.btn-default:hover, .btn.btn-default:focus, .btn.btn-default:active {
  border-color: transparent;
}

.js .animate-box {
  opacity: 0;
}

#fh5co-wrapper {
  overflow-x: hidden;
  position: relative;
}

.fh5co-offcanvas {
  overflow: hidden;
}
.fh5co-offcanvas #fh5co-wrapper {
  overflow: hidden !important;
}

#fh5co-page {
  position: relative;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.fh5co-offcanvas #fh5co-page {
  -moz-transform: translateX(-240px);
  -webkit-transform: translateX(-240px);
  -ms-transform: translateX(-240px);
  -o-transform: translateX(-240px);
  transform: translateX(-240px);
}

@media screen and (max-width: 768px) {
  #fh5co-menu-wrap {
    display: none;
  }
}

#offcanvas-menu {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 99;
  background: #222;
  right: -240px;
  width: 240px;
  padding: 20px;
  overflow-y: scroll !important;
}
#offcanvas-menu ul {
  padding: 0;
  margin: 0;
}
#offcanvas-menu ul li {
  padding: 0;
  margin: 0 0 10px 0;
  list-style: none;
}
#offcanvas-menu ul li a {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.4);
}
#offcanvas-menu ul li a:hover {
  color: #FCC72C;
}
#offcanvas-menu ul li > .fh5co-sub-menu {
  margin-top: 9px;
  padding-left: 20px;
}
#offcanvas-menu ul li.active > a {
  color: #FCC72C;
}
#offcanvas-menu ul .fh5co-sub-menu {
  display: block !important;
}

.fh5co-nav-toggle {
  cursor: pointer;
  text-decoration: none;
}
.fh5co-nav-toggle.active i::before, .fh5co-nav-toggle.active i::after {
  background: #fff;
}
.fh5co-nav-toggle.dark.active i::before, .fh5co-nav-toggle.dark.active i::after {
  background: #fff;
}
.fh5co-nav-toggle:hover, .fh5co-nav-toggle:focus, .fh5co-nav-toggle:active {
  outline: none;
  border-bottom: none !important;
}
.fh5co-nav-toggle i {
  position: relative;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  width: 30px;
  height: 2px;
  color: #fff;
  font: bold 14px/.4 Helvetica;
  text-transform: uppercase;
  text-indent: -55px;
  background: #fff;
  transition: all .2s ease-out;
}
.fh5co-nav-toggle i::before, .fh5co-nav-toggle i::after {
  content: '';
  width: 30px;
  height: 2px;
  background: #fff;
  position: absolute;
  left: 0;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
.fh5co-nav-toggle.dark i {
  position: relative;
  color: #000;
  background: #000;
  transition: all .2s ease-out;
}
.fh5co-nav-toggle.dark i::before, .fh5co-nav-toggle.dark i::after {
  background: #000;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.fh5co-nav-toggle i::before {
  top: -7px;
}

.fh5co-nav-toggle i::after {
  bottom: -7px;
}

.fh5co-nav-toggle:hover i::before {
  top: -10px;
}

.fh5co-nav-toggle:hover i::after {
  bottom: -10px;
}

.fh5co-nav-toggle.active i {
  background: transparent;
}

.fh5co-nav-toggle.active i::before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.fh5co-nav-toggle.active i::after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}

.fh5co-nav-toggle {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 9999;
  display: block;
  margin: 0 auto;
  display: none;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .fh5co-nav-toggle {
    display: block;
    top: 0px;
    margin-top: 2em;
  }
}

@media screen and (max-width: 480px) {
  .col-xxs-12 {
    float: none;
    width: 100%;
  }
}

.row-bottom-padded-lg {
  padding-bottom: 7em;
}
@media screen and (max-width: 768px) {
  .row-bottom-padded-lg {
    padding-bottom: 2em;
  }
}

.row-top-padded-lg {
  padding-top: 7em;
}
@media screen and (max-width: 768px) {
  .row-top-padded-lg {
    padding-top: 2em;
  }
}

.row-bottom-padded-md {
  padding-bottom: 4em;
}
@media screen and (max-width: 768px) {
  .row-bottom-padded-md {
    padding-bottom: 2em;
  }
}

.row-top-padded-md {
  padding-top: 4em;
}
@media screen and (max-width: 768px) {
  .row-top-padded-md {
    padding-top: 2em;
  }
}

.row-bottom-padded-sm {
  padding-bottom: 2em;
}
@media screen and (max-width: 768px) {
  .row-bottom-padded-sm {
    padding-bottom: 2em;
  }
}

.row-top-padded-sm {
  padding-top: 2em;
}
@media screen and (max-width: 768px) {
  .row-top-padded-sm {
    padding-top: 2em;
  }
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.lead {
  line-height: 1.8;
}

.no-js #loader {
  display: none;
}

.js #loader {
  display: block;
  position: absolute;
  left: 100px;
  top: 0;
}

.fh5co-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url(../images/hourglass.svg) center no-repeat #222222;
}

.round {
    display: inline-block;
    height: 30px;
    width: 30px;
    line-height: 30px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    background-color: #222;    
    color: #FFF;
    text-align: center;  
}
.round.hollow {
    display: inline-block;
    height: 30px;
    width: 30px;
    line-height: 30px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    background-color: #FFF;    
    color: #222;
    text-align: center;
    -webkit-box-shadow: 0px 0px 0px 3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 0px 3px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 0px 3px rgba(0,0,0,0.75);
}

.round.round-sm {
    height: 20px;
    width: 20px;
    line-height: 20px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    font-size: 0.7em;
}
.round.round-lg {
    height: 100px;
    width: 100px;
    line-height: 40px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    font-size: 1.5em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.round.blue {
    background-color: #3EA6CE;
}

.round.orange {
    background-color: #FF6701;
}

.round.green {
    background-color: #42A129;
}

.round.yellow {
    background: rgba(19, 35, 47, 0.2);
    
}

.round.hollow.blue {
    color: #3EA6CE;   
    background-color: #FFF;    
    -webkit-box-shadow: 0px 0px 0px 3px #3EA6CE;
    -moz-box-shadow: 0px 0px 0px 3px #3EA6CE;
    box-shadow: 0px 0px 0px 3px #3EA6CE;
}

.round.hollow.yellow {
    -webkit-box-shadow: 0px 0px 0px 3px #FCC72C;
    -moz-box-shadow: 0px 0px 0px 3px #FCC72C;
    box-shadow: 0px 0px 0px 3px #FCC72C;
}

.round.hollow.orange {
    color: #FF6701;
    background-color: #FFF;    
    -webkit-box-shadow: 0px 0px 0px 3px #FF6701;
    -moz-box-shadow: 0px 0px 0px 3px #FF6701;
    box-shadow: 0px 0px 0px 3px #FF6701;
}

.round.hollow.green {
    color: #42A129;
    background-color: #FFF;    
    -webkit-box-shadow: 0px 0px 0px 3px #42A129;
    -moz-box-shadow: 0px 0px 0px 3px #42A129;
    box-shadow: 0px 0px 0px 3px #42A129;
}

/*# sourceMappingURL=style.css.map */


#register a {
  text-decoration: none;
  color: #FCC72C;
  -webkit-transition: .5s ease;
  transition: .5s ease;
}
#register a:hover {
  color: #179b77;
}
#register .form {
  background: rgba(19, 35, 47, 0.9);
  padding: 40px;
  max-width: 600px;
  margin: 100px auto;
  border-radius: 4px;
  box-shadow: 0 4px 10px 4px rgba(19, 35, 47, 0.3);
}
#register .tab-group {
  list-style: none;
  padding: 0;
  margin: 0 0 40px 0;
}
#register .tab-group:after {
  content: "";
  display: table;
  clear: both;
}
#register .tab-group li a {
  display: block;
  text-decoration: none;
  padding: 15px;
  background: rgba(160, 179, 176, 0.25);
  color: #a0b3b0;
  font-size: 20px;
  float: left;
  width: 50%;
  text-align: center;
  cursor: pointer;
  -webkit-transition: .5s ease;
  transition: .5s ease;
}
#register .tab-group li a:hover {
  background: #179b77;
  color: #ffffff;
}
#register .tab-group .active a {
  background: #FCC72C;
  color: #ffffff;
}
#register .tab-content > div:not(:first-child) {
  display: none;
}
#register h1 {
  text-align: center;
  color: #ffffff;
  font-weight: 300;
  margin: 0 0 40px;
}
#register label {
  position: absolute;
  -webkit-transform: translateY(6px);
          transform: translateY(6px);
  left: 13px;
  color: rgba(255, 255, 255, 0.5);
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  -webkit-backface-visibility: hidden;
  pointer-events: none;
  font-size: 22px;
}
#register label .req {
  margin: 2px;
  color: #1ab188;
}
#register label.active {
  -webkit-transform: translateY(50px);
          transform: translateY(50px);
  left: 2px;
  font-size: 14px;
}
#register label.active .req {
  opacity: 0;
}
#register label.highlight {
  color: #ffffff;
}
#register input, #register textarea {
  font-size: 22px;
  display: block;
  width: 100%;
  height: 100%;
  padding: 5px 10px;
  background: none;
  background-image: none;
  border: 1px solid #a0b3b0;
  color: #ffffff;
  border-radius: 0;
  -webkit-transition: border-color .25s ease, box-shadow .25s ease;
  transition: border-color .25s ease, box-shadow .25s ease;
}
#register input:focus, #register textarea:focus {
  outline: 0;
  border-color: #1ab188;
}
#register textarea {
  border: 2px solid #a0b3b0;
  resize: vertical;
}
#register .field-wrap {
  position: relative;
  margin-bottom: 40px;
}
#register .top-row:after {
  content: "";
  display: table;
  clear: both;
}
#register .top-row > div {
  float: left;
  width: 48%;
  margin-right: 4%;
}
#register .top-row > div:last-child {
  margin: 0;
}
#register .button {
  border: 0;
  outline: none;
  border-radius: 0;
  padding: 15px 0;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .1em;
  background: #FCC72C;
  color: #ffffff;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-appearance: none;
}
#register .button:hover, #register .button:focus {
  background: #179b77;
}
#register .button-block {
  display: block;
  width: 100%;
}
#register .forgot {
  margin-top: -20px;
  text-align: right;
}

